import {gsap} from 'gsap'

export function enlargeThumb(imgSrc) {
  const body = document.querySelector('body')

  const imgExt = imgSrc.split('.')[imgSrc.split('.').length -1]
  const imgFullName = imgSrc.split('/').slice(-1).join('/').split('.')[0]
  const imgPath = imgSrc.split('/').slice(-3, -1).join('/')
  const imgName = imgFullName.split('-')[0]
  console.log('imgExt: ', imgExt)
  console.log('imgFullName: ', imgFullName)
  console.log('imgPath: ', imgPath)
  console.log('imgName: ', imgName)

  const overlay = document.createElement('div')
  overlay.classList.add('thumbsOverlay')
  const container = document.createElement('div')
  container.classList.add('thumbsOverlay__container')
  const closeBtn = document.createElement('div')
  closeBtn.classList.add('thumbsOverlay__closeBtn')
  const closeIcon = document.createElement('img')
  closeIcon.classList.add('thumbsOverlay__closeBtn-icon')
  closeIcon.src = 'img/close_icon_dark.svg'
  const img = document.createElement('img')
  img.classList.add('thumbsOverlay__screenshot')
  img.src = imgSrc
  img.srcset = `
  /${imgPath}/${imgName}-400w.${imgExt} 400w,
  /${imgPath}/${imgName}-768w.${imgExt} 768w,
  /${imgPath}/${imgName}-964w.${imgExt} 964w,
  /${imgPath}/${imgName}-1200w.${imgExt} 1200w
  `
  img.sizes = `
  (max-width: 768px) 768px,
  (max-width: 964px) 964px, 1200px;
  `

  closeBtn.addEventListener('click', closeThumbOverlay)
  overlay.addEventListener('click', (e) => {
    if(e.target.classList.contains('.thumbsOverlay__container')) return
    closeThumbOverlay()
  })
  
  body.appendChild(overlay)
  overlay.appendChild(container)
  container.appendChild(closeBtn)
  container.appendChild(img)
  closeBtn.appendChild(closeIcon)

  const enlargeThumbAnimation = gsap.timeline()
  .from(overlay, {opacity: 0})
  .from(container, {opacity: 0, y: 25}, '-=.3')
}


export function closeThumbOverlay() {
  const thumbOverlay = document.querySelector('.thumbsOverlay')
  const thumbContainer = document.querySelector('.thumbsOverlay__container')
  const closeThumbOverlayAnimation = gsap.timeline({
    onComplete: function() {thumbOverlay.remove()}
  })
  .to(thumbContainer, {y: -20, opacity: 0, ease: 'power2.in'})
  .to(thumbOverlay, {opacity: 0, ease: 'power2.in'}, '-=.3')
}