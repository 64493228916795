import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(SplitText, ScrollTrigger)
// gsap.core.globals("ScrollTrigger", ScrollTrigger);

// import { enlargeThumb, closeThumbOverlay } from './thumbAnimation';
import { enlargeThumb } from './thumbAnimation';


// ******************
// Selectors
// ******************
const body = document.querySelector('body')
// Hero
const heroCard = document.querySelector('.landing--card')
const heroTitle = document.querySelector('.landing--title')
// Benefits
const benefits = document.querySelectorAll('.benefits--list__item')
// Solutions
const solutionSection = document.querySelector('.solutions')
const solutionTitle = document.querySelector('.solutions--title')
const products = document.querySelectorAll('.solutions--product')
const circleTexts = document.querySelectorAll('.circle--text p')
const circleEls = document.querySelectorAll('.circle')
const thumbs = document.querySelectorAll('.solutions--product__thumbs .glide__slide img')
// Clients
const clientSection = document.querySelector('.clients')
const clientText = document.querySelector('.clients--text')
const clientSplit = new SplitText(clientText, { type: 'words' })
// Certificados
const certificados = document.querySelectorAll('.certificados--item')



// ******************
// Home Card Animation
// ******************
const heroTitleSplit = new SplitText(heroTitle, { type: 'lines' })
const heroAnimation = gsap.timeline()
  .from(heroCard, { y: 40, opacity: 0, duration: 1 })
  .from(heroTitleSplit.lines, { rotateX: 90, scaleY: 0.7, opacity: 0, y: 20, stagger: { amount: .5 } })


// ******************
// Benefits Animation
// ******************
benefits.forEach((benefit) => {
  const big = benefit.querySelector('.benefits--list__item-big')
  const small = benefit.querySelector('.benefits--list__item-small')
  const bigSplit = new SplitText(big, { type: 'words, chars' })
  const smallSplit = new SplitText(small, { type: 'words, chars' })
  const bigChars = bigSplit.chars

  gsap.set(bigChars, { opacity: 0, scaleY: 3, yPercent: 60, transformOrigin: "50% 0%" });
  const benefitAnimation = gsap.timeline({ scrollTrigger: { trigger: benefit, start: 'top 70%' } })
  benefitAnimation.to(bigChars, { duration: .8, stagger: .03, ease: 'back', yPercent: 0 });
  benefitAnimation.to(bigChars, { duration: .6, stagger: .03, ease: "power2.out", opacity: 1, scaleY: 1 }, "<+=0.2");
  // benefitAnimation.from(small, {x: '-50%', opacity: 0, ease: 'back'}, '+.3')
  benefitAnimation.from(small, { x: '-50%', opacity: 0 }, '+.3')
  benefitAnimation.from(benefit, { borderColor: 'transparent' }, '<')
})


// *******************
// Circles Animations
// *******************
// #region Circles
// Text rotation
circleTexts.forEach((circleText) => {
  circleText.innerHTML = circleText.innerText
    .split("")
    .map((char, i) => `<span style="transform:rotate(${i * 12}deg)">${char}</span>`)
    .join("")
})
gsap.to([circleEls], { rotate: 360, duration: 10, repeat: -1, ease: 'linear' })

circleEls.forEach((circle) => {
  const innerCircle = circle.querySelector('.circle--logo')
  const innerText = circle.querySelector('.circle--text')
  // Hover IN
  circle.addEventListener('mouseenter', () => {
    gsap.to(innerCircle, { scale: 1.5, duration: 0.5, ease: 'back.out' })
    gsap.to(innerText, { color: '#19355f' })
  })

  // Hover OUT
  circle.addEventListener('mouseleave', () => {
    gsap.to(innerCircle, { scale: 1, duration: 0.5 })
    gsap.to(innerText, { color: '#FF8300' })
  })

  // Open overlay
  circle.addEventListener('click', (e) => {
    const x = e.clientX
    const y = e.clientY
    const src = circle.getAttribute('data-product')
    createVideoOverlay(x, y, src)
  })
})


function createVideoOverlay(x, y, src) {
  const overlay = document.createElement('div')
  overlay.classList.add('solutions--overlay')
  overlay.style.top = `${y}px`
  overlay.style.left = `${x}px`
  const container = document.createElement('div')
  container.classList.add('solutions--overlay__container')
  const videoFrame = document.createElement('iframe')
  videoFrame.src = src
  videoFrame.frameborder = 0
  videoFrame.allow = 'autoplay; fullscreen; picture-in-picture'
  videoFrame.allowFullscreen = true
  const scriptEl = document.createElement('script')
  scriptEl.src = 'https://player.vimeo.com/api/player.js'
  const closeBtn = document.createElement('div')
  closeBtn.classList.add('solutions--overlay__closeBtn')
  const closeIcon = document.createElement('img')
  closeIcon.src = 'img/close_icon.svg'

  solutionSection.appendChild(overlay)
  container.appendChild(videoFrame)
  container.appendChild(scriptEl)
  closeBtn.appendChild(closeIcon)
  container.appendChild(closeBtn)
  overlay.appendChild(container)

  const overlayAnimation = gsap.timeline()
    .fromTo(overlay, { scale: 0 }, { scale: 1, translateX: 0, translateY: 0, ease: 'linear', duration: 0.2, backgroundColor: '#164e87' })
    .to(overlay, { top: 0, left: 0, width: '100%', height: '100%', borderRadius: 0, translateX: '50%', translateY: '50%', duration: 0.3, ease: 'power4.out' })
    .from(closeBtn, { opacity: 0, y: 20 })
    .from(videoFrame, { opacity: 0 })

  body.style.overflow = 'hidden'
  closeBtn.addEventListener('click', () => { closeVideoOverlay(closeBtn, overlay, videoFrame) })
}


function closeVideoOverlay(closeBtn, overlay, videoFrame) {
  const iframe = document.querySelector('iframe')
  const player = new Vimeo.Player(iframe)
  player.pause()
  overlay.style.transformOrigin = 'top right'
  body.style.overflow = 'auto'
  function cleanDom() {
    overlay.remove()
  }
  const closeAnimation = gsap.timeline({ onComplete: cleanDom, })
    .to(closeBtn, { opacity: 0, y: -50 })
    .to(videoFrame, { opacity: 0, y: -30 }, '<')
    .to(overlay, { width: '100vh', scale: 2, borderRadius: '50%', y: '100%' })
    .to(overlay, { scale: 0, y: '-100%', x: '200%', duration: 2 }, '<')
}
// #endregion


// *******************
// Solutions Animation
// *******************
// gsap.from(solutionTitle, { y: 30, opacity: 0, scrollTrigger: { trigger: solutionSection, start: 'top 70%' } })

// products.forEach((product) => {
//   const strapline = product.querySelector('.solutions--product__strapline')
//   const description = product.querySelector('.solutions--product__description')
//   const verbutton = product.querySelector('.solutions--product__button')
//   const productAnimation = gsap.timeline({
//     scrollTrigger: {
//       trigger: product,
//       scroller: 'body',
//       start: 'top 50%',
//       markers: false,
//     }
//   })
//     .from(strapline, { x: '-62%', opacity: 0 })
//     .from(description, { y: 10, opacity: 0 }, '<.3')
//     // .from(verbutton, { y: 10, opacity: 0 }, '<.3')
//     .from(verbutton, { y: 10 }, '<.3')
// })


// ******************
// Enlarge thumbnails
// ******************
thumbs.forEach((thumb) => {
  thumb.addEventListener('click', () => {
    const imgSrc = thumb.src
    enlargeThumb(imgSrc)
  })
})



// *******************
// Clients Animation
// *******************
gsap.from(clientSplit.words, { y: 10, opacity: 0, scrollTrigger: { trigger: clientSection, start: 'top 70%' }, stagger: { amount: 1.5 } })



// **********************
// Certificados Animation
// **********************
certificados.forEach((certificado) => {
  certificado.addEventListener('click', () => {
    certificado.classList.toggle('active')
    const content = certificado.querySelector('.certificados--item__content')

    if (certificado.classList.contains('active')) { gsap.to(certificado, { height: 'auto' }) }

    else { gsap.to(certificado, { height: '5em' }) }
  })
})


