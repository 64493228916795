import Glide from '@glidejs/glide'

const glide = new Glide('.glide', {
  type: 'carousel',
  perView: 4,
  autoplay: 2000,
  hoverpause: true,
  animationDuration: 2000,
  gap: 24,
  breakpoints: {
    900: { perView: 3 },
    768: { perView: 2 },
  }
})
glide.mount()


const glideSolution1 = new Glide('.glideSolution_1', {
  type: 'carousel',
  perView: 4,
  autoplay: 4000,
  hoverpause: true,
  animationDuration: 2000,
  gap: 24,
  breakpoints: {
    900: { perView: 3 },
    768: { perView: 2 },
  }
})
glideSolution1.mount()


const glideSolution2 = new Glide('.glideSolution_2', {
  type: 'carousel',
  perView: 4,
  autoplay: 4000,
  hoverpause: true,
  animationDuration: 2000,
  gap: 24,
  breakpoints: {
    900: { perView: 3 },
    768: { perView: 2 },
  }
})
glideSolution2.mount()



