import {gsap} from 'gsap'


// Nav Animation Elements
const hiddenNav = document.querySelector('.nav--hidden')
const burger = document.querySelector('.nav--burger')
const line1 = document.querySelector('.nav--burger__line1')
const line2 = document.querySelector('.nav--burger__line2')
const line3 = document.querySelector('.nav--burger__line3')
const hiddenItems = document.querySelectorAll('.nav--hidden__list-item')



// Burger menu animation
burger.addEventListener('click', () => {
  hiddenNav.classList.toggle('nav-active')

  if(hiddenNav.classList.contains('nav-active')) {
    const navIn = gsap.timeline()
      .to(hiddenNav, {x: '100%', ease: 'power2.in'})
      .to(line1, {backgroundColor: "#ffffff", rotate: 45, y: 6}, '<')
      .to(line2, {backgroundColor: "#ffffff", opacity: 0}, '<')
      .to(line3, {backgroundColor: "#ffffff", rotate: -45, y: -6}, '<')
      .from(hiddenItems, {x: -25, opacity: 0, duration: 0.3, ease: 'power2.in', stagger: 0.1}, ">-.1")
  } else {
    const navOut = gsap.timeline()
      .to(hiddenNav, {x: '0%', ease: 'power2.out'})
      .to(line1, {backgroundColor: "#173255", rotate: 0, y: 0}, '<')
      .to(line2, {backgroundColor: "#173255", opacity: 1}, '<')
      .to(line3, {backgroundColor: "#173255", rotate: 0, y: 0}, '<')
  }
})

hiddenItems.forEach((item) => {
  item.addEventListener('click', () => {
    hiddenNav.classList.toggle('nav-active')
    const navOut = gsap.timeline()
    .to(hiddenNav, {x: '0%', ease: 'power2.out'})
    .to(line1, {backgroundColor: "#173255", rotate: 0, y: 0}, '<')
    .to(line2, {backgroundColor: "#173255", opacity: 1}, '<')
    .to(line3, {backgroundColor: "#173255", rotate: 0, y: 0}, '<')
  })
})